.bedManagementList {
  margin-bottom: 0.5em;

  .filterUI {
    & > div {
      flex: 1 0 20%;
      max-width: 25%;
    }

    .buttonWrapper {
      max-width: 15%;
      align-self: flex-end;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: flex-end;

      & > button {
        width: 100%;
      }

      & > button:first-child {
        margin-right: 0.75em;
      }
    }
  }
}
