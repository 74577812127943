.roomReservationFilter {
  margin-bottom: 0.5em;

  .filterUI {
    & > div {
      flex: 1 0 18%;
      max-width: 18%;
    }
  }
}

.btn-wrapper {
  display: flex;
  gap: 1em;
}

.pt-10   {
  padding-top: 12px;
}
